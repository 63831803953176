/*
https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-weight
Common weight name mapping
The numerical values 100 to 900 roughly correspond to the following common weight names:
Value	Common weight name
100	Thin (Hairline)
200	Extra Light (Ultra Light)
300	Light
400	Normal
500	Medium
600	Semi Bold (Demi Bold)
700	Bold
800	Extra Bold (Ultra Bold)
900	Black (Heavy)

 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Quicksand:wght@500;600;700&display=swap");
/*------------quicksand************/
/*
@font-face {
  font-family: 'Quicksand';
  src: url('/assets/fonts/Quicksand-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/assets/fonts/Quicksand-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/assets/fonts/Quicksand-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
*/
/*-------------poppins************/
/*
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
 */
/*-------------------************/
@font-face {
  font-family: 'AwesomePro';
  src: url("/assets/fonts/Font Awesome 5 Pro-Light-300.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'AwesomePro';
  src: url("/assets/fonts/Font Awesome 5 Pro-Regular-400.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AwesomePro';
  src: url("/assets/fonts/Font Awesome 5 Pro-Solid-900.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AwesomePro';
  src: url("/assets/fonts/Font Awesome 5 Pro-Regular-400.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AwesomePro';
  src: url("/assets/fonts/Font Awesome 5 Pro-Light-300.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

/*-------------------************/
@font-face {
  font-family: 'Hiragino Kaku Gothic ProN';
  src: url("/assets/fonts/hiragino-kaku-gothic-pron-w3.otf") format("opentype");
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

html,
body {
  overflow-x: hidden;
  height: 100%;
}

html {
  font-size: 62.5%;
}

/* =10px */
body {
  font-size: 1.6rem;
  font-family: Poppins, sans-serif;
  color: #30353a;
  line-height: 1.6;
  font-weight: normal;
}

.font-hiragino-kaku-gothic-pron-w3 {
  font-family: "Hiragino Kaku Gothic ProN";
}

.font-awesome-pro {
  font-family: AwesomePro;
}

.font-poppins {
  font-family: Poppins, sans-serif;
}

.font-quicksand {
  font-family: Quicksand, sans-serif;
}

.font-awesome-bold {
  font-weight: 900;
}

.font-bold {
  font-weight: 600;
}

.font-semi-bold {
  font-weight: 500;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}

h1 {
  font-size: 4.5rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.6rem;
}

h4 {
  font-size: 2.2rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.6rem;
}

h7 {
  font-size: 1.4rem;
}

h8 {
  font-size: 1.2rem;
}

.container {
  margin: auto;
  position: relative;
}

@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1600px) {
  .container {
    width: 1600px;
  }
}

h2.heading {
  cursor: default;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #30353a;
  font-size: 4.0rem;
  line-height: 1.2;
  margin-bottom: 33px;
}

h2.heading + p {
  text-align: center;
  color: #30353a;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 300;
}

.btn {
  border-radius: 10px;
  padding: 13px 25px;
  font-size: 1.8rem;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none !important;
}

.btn:focus {
  box-shadow: none !important;
}

.btn-outline-secondary:hover, .btn-outline-secondary:active {
  border-color: #e95024 !important;
  background: #e95024 !important;
  color: #fff;
}

.btn-outline-secondary:focus {
  border-color: #ff8b52 !important;
  background: #ff8b52 !important;
}

.btn-primary {
  background-color: #ff8b52;
  border-color: #ff8b52;
  color: #fff;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  background-color: #e95024 !important;
  border-color: #e95024 !important;
  color: #fff;
}

.btn-primary:active:focus {
  background-color: #ff8b52 !important;
  border-color: #ff8b52 !important;
  color: #fff;
}

.form input {
  border: 1px solid #dde3ee;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  margin: 0 0 17px 0px;
  padding: 17px;
  box-sizing: border-box;
  font-size: 1.8rem;
  outline: none !important;
  color: #4f5864;
}

.form input::placeholder {
  color: #909cac;
}

.form button {
  margin-top: 25px;
}

.form button:hover, .form button:active, .form button:focus {
  background: #e95024;
}

.form .form_info {
  text-align: left;
  margin-top: -15px;
  margin-bottom: 15px;
}

.form .form_info a {
  text-decoration: none;
  color: #e95024;
}

.form .form_info span {
  font-size: 1.4rem;
}

.my-terms_accepted {
  position: relative;
  margin-top: 5px;
  font-size: 1.6rem;
}

.my-terms_accepted input {
  width: 1.8rem;
}

.my-terms_accepted a {
  color: #ff8b52;
  text-decoration: none;
}

.my-terms_accepted a:hover {
  color: #e95024;
}

input.form-check-input {
  margin: 0.5rem 0;
  font-size: 5rem;
  padding: 0;
  border-radius: 5px;
}

.hidden {
  display: none;
}

/******************** Custom checkbox ************************/
input[type="checkbox"], input[type="radio"] {
  position: absolute;
  outline: none;
  right: 9000px;
}

input[type="checkbox"] + .form-check-label {
  outline: none;
  border: none;
}

input[type="checkbox"] + .form-check-label:before {
  content: "\f0c8";
  font-family: "AwesomePro";
  font-size: 1.8rem;
  border-radius: 5px;
  color: #dde3ee;
  speak: none;
  font-style: normal;
  font-weight: 300;
  text-transform: none;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  width: 1.8rem;
  display: inline-block;
  margin-right: 5px;
  outline: none;
  border: none;
  transform: scale(1.1);
}

input[type="checkbox"]:checked + .form-check-label:before {
  content: "\f14a";
  color: #ff8b52;
  font-weight: bold;
  animation: effect 250ms ease-in;
  outline: none;
  border: none;
}

input[type="checkbox"]:disabled + .form-check-label {
  color: #fff;
}

input[type="checkbox"]:disabled + .form-check-label:before {
  content: "\f0c8";
  color: #fff;
}

/*Radio box*/
input[type="radio"] + .form-check-label {
  outline: none;
}

input[type="radio"] + .form-check-label:before {
  content: "\f111";
  font-family: "AwesomePro";
  font-size: 1.8rem;
  border-radius: 5px;
  color: #dde3ee;
  speak: none;
  font-style: normal;
  font-weight: 300;
  text-transform: none;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  width: 1.8rem;
  display: inline-block;
  margin-right: 5px;
  outline: none;
  border: none;
  transform: scale(1.1);
}

input[type="radio"]:checked + .form-check-label:before {
  content: "\f192";
  color: #ff8b52;
  font-weight: bold;
  animation: effect 250ms ease-in;
  outline: none;
  border: none;
}

input[type="radio"]:disabled + .form-check-label {
  color: #ccc;
}

input[type="radio"]:disabled + .form-check-label:before {
  content: "\f192";
  color: #ccc;
}

/********************animation ************************/
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes effect {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

.login {
  min-height: 550px;
  background-color: #fff;
}

.login-page {
  height: 100%;
  position: relative;
  padding: 0 0 0 0;
  margin: auto;
}

.row {
  position: relative;
  background-color: #fff;
  margin: auto;
  text-align: left;
  height: 100%;
}

.logo-image {
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 200px;
  width: 140px;
  height: auto;
}

@media screen and (max-width: 960px) {
  .logo-image {
    position: relative;
    left: 50%;
    margin-left: -50px;
    width: 100%;
  }
  .logo-image img {
    width: 100px;
  }
}

.col-left {
  position: absolute;
  height: 100%;
  min-height: 860px;
  max-width: 800px;
  text-align: right;
  overflow: hidden;
}

.col-left img.bg-image {
  min-height: 100%;
  min-width: 100%;
}

@media screen and (max-width: 1050px) {
  .col-left {
    display: none;
  }
}

@media screen and (min-width: 1600px) {
  .col-left {
    width: 800px;
  }
}

.col-right {
  width: 50%;
  height: 100%;
  min-height: 860px;
  z-index: 1;
  max-width: 650px;
  background-color: #fff;
  margin: auto;
}

@media screen and (min-width: 650px) {
  .col-right {
    min-width: 580px;
  }
}

@media screen and (min-width: 1050px) {
  .col-right {
    margin-left: 50%;
  }
}

@media screen and (min-width: 1600px) {
  .col-right {
    margin-left: 800px;
  }
}

@media screen and (max-width: 768px) {
  .col-right {
    width: 90%;
    padding-top: 50px;
  }
}

.form.form-register {
  display: none;
}

.form {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0 70px;
}

.form .header {
  padding-bottom: 50px;
  width: 100%;
  text-align: right;
}

.form .header.bottom {
  padding-top: 20px;
  text-align: center;
}

.form .title {
  color: #30353a;
  padding-bottom: 25px;
}

@media screen and (min-width: 960px) {
  .form .header.bottom {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .form {
    padding: 0px 0px 70px 0px;
  }
  .form .header.top {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .form .title {
    text-align: center;
  }
  .form .btn-login {
    width: 100%;
    text-align: center;
  }
}

.form .main {
  height: 100%;
  margin-top: 15%;
}

@media screen and (max-width: 650px) {
  .form .main {
    margin-top: 0;
  }
}

@media screen and (min-width: 1050px) {
  .form .main {
    margin-top: 25%;
  }
}

.form .message {
  margin: 15px 0 0;
  font-size: 1.6rem;
  color: #909cac;
}

.form .message a {
  color: #ff8b52;
  text-decoration: none;
}

.form .message a:hover {
  color: #e95024;
}

.form button {
  font-size: 2.2rem;
}

.form .social-auth {
  display: flex;
  margin: 20px 0 50px 0;
  font-size: 1.8rem;
}

.form .social-auth a {
  text-decoration: none;
}

.form .social-auth .social-auth-content {
  display: flex;
  margin: auto;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .form .social-auth .social-auth-content {
    text-align: center;
    display: block;
  }
}

.form .google-auth {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
  color: #fff;
  background-color: #1972e7;
  border-radius: 10px;
  padding: 8.5px 12px 8.5px 35px;
}

.form .google-auth img {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  margin-left: 17px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

@media screen and (max-width: 450px) {
  .form .google-auth {
    margin-right: 1px;
  }
}

.form a.facebook-auth {
  width: 65px;
  height: 65px;
  display: inline-block;
  background-color: transparent;
  border-radius: 10px;
  border-color: #dde3ee;
  border-width: 1px;
  border-style: solid;
  background-image: url("/assets/images/login/facebook.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 38px 38px;
}

.form a.facebook-auth:hover {
  background-color: #1972e7;
  background-image: url("/assets/images/login/facebook-white.svg");
}

.form hr.divider {
  max-width: 500px;
  overflow: visible;
  padding: 0;
  margin-bottom: 52px;
  border: none;
  border-top: 1px solid #dde3ee;
  color: #dde3ee;
  text-align: center;
}

.form hr.divider:after {
  content: 'Or';
  display: inline-block;
  position: relative;
  top: -1.6rem;
  font: normal 14px/14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0 16px;
  background: #fff;
}

.form .form-field-info {
  margin-top: -15px;
}

.form .form-field-info .text-danger {
  text-decoration: none;
}

.form .my-terms_accepted.has_error {
  color: #dc3545;
}

.form .my-terms_accepted a {
  padding-top: 0.5rem;
}
