// COLOR
$color-white: #fffff;
$color-black: #30353a; //text
$color-black-2: #23272d; //faq text title, checkout text
$color-grey: #909cac; //placeholder
$color-grey-1: #dde3ee; //input border color
$color-grey-2: #4f5864; //nav normal color,learn detail bold text
$color-grey-3: #e6f1f5; //home header bg color
$color-grey-4: #f4f8f9;//pricing faq bg
$color-blue: #1972e7; //sign in with socials
$color-orange: #ff8b52; //primary button color
$color-orange-2: #e95024; //primary button color hover/select
$button-padding: 16px;  //normal primary button padding
$button-padding-large-left: 35px;
$button-padding-medium: 25px;
$button-padding-google-top-bottom: 10px;
$button-padding-google-left: 17px;
$button-padding-google-right: 12px;
$max-input-width: 500px;

// FONTS
$font-awesome-pro: AwesomePro;
$font-poppins:  Poppins,sans-serif;
$font-quicksand: Quicksand,sans-serif;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 500;//600;
$font-weight-bold: 600;//700;
$font-hiragino-kaku-gothic-pron-w3: 'Hiragino Kaku Gothic ProN';
$font-size-base     : 1.6rem;
$font-size-h1       : 4.5rem;
$font-size-h2       : 3.0rem;
$font-size-h3       : 2.6rem;
$font-size-h4       : 2.2rem;
$font-size-h5       : 1.8rem;
$font-size-h6       : 1.6rem;
$font-size-h7       : 1.4rem;
$font-size-h8       : 1.2rem;

//Custom Hamburger
$hamburger-padding-x: 15px!default;
$hamburger-padding-y: 15px!default;
$hamburger-layer-width: 25px!default;
$hamburger-layer-height: 3px!default;
$hamburger-layer-spacing: 4px!default;
$hamburger-layer-color: #fff
