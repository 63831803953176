@import "./variables";
@import "./mixins";
@import "./fonts";
@import "./common";
.login {
  min-height: 550px;
  background-color: #fff;
}
.login-page {
  height: 100%;
  position: relative;
  //max-width: 1600px;
  padding: 0 0 0 0;
  margin: auto;
}
.row{
  position: relative;
  background-color: #fff;
  margin: auto;
  text-align: left;
  //box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.24);
  height: 100%;
}
.logo-image{
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 200px;
  width: 140px;
  height: auto;
  @media screen and (max-width: 960px) {
    position: relative;
    //left: 20px;
    left:50%;
    margin-left: -50px;
    width: 100%;
    img{
      width: 100px;
    }
  }
}
.col-left{
  position: absolute;
  height: 100%;
  min-height: 860px;
  max-width: 800px;
  text-align: right;
  overflow: hidden;
  img.bg-image{
    min-height: 100%;
    min-width: 100%;
  }
  @media screen and (max-width: 1050px) {
    display: none;
  }
  @media screen and (min-width: 1600px) {
    width: 800px;
  }
}
.col-right{
  width: 50%;
  height: 100%;
  min-height: 860px;
  z-index: 1;
  max-width: 650px;
  background-color: #fff;

  margin: auto;
  @media screen and (min-width: 650px) {
    min-width: 580px;
  }
  @media screen and (min-width: 1050px) {
    margin-left: 50%;
  }
  @media screen and (min-width: 1600px) {
    margin-left: 800px;
  }
  @media screen and (max-width: 1050px) {
    //padding-top: 25%;
    //margin-left: 0%;
    //width: 90%;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    padding-top: 50px;
  }
}
.form.form-register{
  display: none;
}
.form{
  position: relative;
  box-sizing: border-box;
  //height: 100%;
  width: 100%;
  //max-width: 640px;
  padding: 0 70px;
  .header{
    padding-bottom: 50px;
    width: 100%;
    text-align: right;
    &.bottom{
      padding-top: 20px;
      text-align: center;
    }
  }
  .title{
    color: $color-black;
    //padding-top: 110px;
    padding-bottom: 25px;
  }
  @media screen and (min-width: 960px) {
    .header.bottom{
      display: none;
    }
  }
  @media screen and (max-width: 960px) {
    padding: 0px 0px 70px 0px;
    .header.top{
      display: none;
    }
  }
  @media screen and (max-width: 960px) {
    .title {
      text-align: center;
    }
    .btn-login{
      width: 100%;
      text-align: center;
    }
    button{
      //width: 250px;
    }
  }
}
.form .main{
  height: 100%;
  margin-top: 15%;
  @media screen and (max-width: 650px) {
    margin-top: 0;
  }
  @media screen and (min-width: 1050px) {
    //position: absolute;
    margin-top: 25%;
  }
}
.form .message {
  margin: 15px 0 0;
  font-size: $font-size-h6;
  color: $color-grey;
  a {
    color: $color-orange;
    text-decoration: none;
  }
  a:hover{
    color: $color-orange-2;
  }
}
.form .form-check-label{
  //padding-left: 25px;
}
.form button{
  font-size: $font-size-h4;
  //width: 214px;
  //height: 63px;
}
.form .social-auth{
  display: flex;
  margin: 20px 0 50px 0;
  font-size: $font-size-h5;
  a{
    text-decoration: none;
  }
  @media screen and (max-width: 450px) {
    //text-align: center;
  }
  .social-auth-content{
    display: flex;
    margin: auto;
    width: 100%;
    @media screen and (max-width: 960px) {
      text-align: center;
      display: block;
      //width: 100%;
    }
  }
}
.form .google-auth{
  position: relative;
  display: inline-block;
  //margin-right: 20px;
  margin-bottom: 8px;
  color: #fff;
  background-color: $color-blue;
  border-radius: 10px;
  padding: 8.5px $button-padding-google-right 8.5px $button-padding-large-left;
  img{
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    margin-left: $button-padding-google-left;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
  }
  @media screen and (max-width: 450px) {
    //position: absolute;
    margin-right: 1px;
  }
}
.form a.facebook-auth{
  //margin-left: 20px;
  width: 65px;
  height: 65px;
  display: inline-block;
  background-color: transparent;
  border-radius: 10px;
  border-color: $color-grey-1;
  border-width: 1px;
  border-style: solid;
  background-image: url('/assets/images/login/facebook.svg') ;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 38px 38px;
  //img{
  //  padding: 15px;
  //  height: 65px;
  //  width: 65px;
  //  content: url('/assets/images/login/facebook.svg');
  //}
  &:hover{
    background-color: $color-blue;
    //img{
    //  content: url('/assets/images/login/facebook-white.svg');
    //  //filter: invert(100%) sepia(4%) saturate(7481%) hue-rotate(292deg) brightness(100%) contrast(96%);
    //}
    background-image: url('/assets/images/login/facebook-white.svg') ;
  }
}
.form hr.divider {
  max-width: 500px;
  overflow: visible;
  padding: 0;
  margin-bottom: 52px;
  border: none;
  border-top: 1px solid $color-grey-1;
  color: $color-grey-1;
  text-align: center;
}
.form hr.divider:after {
  content: 'Or';
  display: inline-block;
  position: relative;
  top: -1.6rem;
  font: normal 14px/14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0 16px;
  background: #fff;
}

.form .form-field-info{
  margin-top: -15px;
  .text-danger{
    text-decoration: none;
  }
}
.form .my-terms_accepted.has_error{
  color: #dc3545;
}
.form .my-terms_accepted a{
  padding-top: 0.5rem;
}
//
//.my-terms_accepted input[type="checkbox"], input[type="radio"]{
//  position: absolute;
//  right: 9000px;
//}
//
//.my-terms_accepted input[type="checkbox"] + .form-check-label:before{
//  content: "\f0c8";
//  font-family: "Font Awesome 5 Free";
//  speak: none;
//  font-style: normal;
//  font-weight: normal;
//  font-variant: normal;
//  text-transform: none;
//  line-height: 1.8;
//  -webkit-font-smoothing:antialiased;
//  width: 1.8rem;
//  display: inline-block;
//  margin-right: 5px;
//}
//
//.my-terms_accepted input[type="checkbox"]:checked + .form-check-label:before{
//  content: "\f14a";
//  color: $color-orange;
//  font-weight: bold;
//  animation: effect 250ms ease-in;
//}
//
//.my-terms_accepted input[type="checkbox"]:disabled + .form-check-label{
//  color: #fff;
//}
//
//.my-terms_accepted input[type="checkbox"]:disabled + .form-check-label:before{
//  content: "\f0c8";
//  color: #fff;
//}
