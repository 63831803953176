* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

html,
body {
  overflow-x: hidden;
  height:100%;
}

html {
  font-size: 62.5%;
} /* =10px */

body {
  font-size: $font-size-base;
  font-family: $font-poppins;
  color: $color-black;
  line-height: 1.6;
  font-weight: normal;
}

.font-hiragino-kaku-gothic-pron-w3{
  font-family: $font-hiragino-kaku-gothic-pron-w3;
}
.font-awesome-pro{
  font-family: $font-awesome-pro;
}
.font-poppins{
  font-family: $font-poppins;
}
.font-quicksand{
  font-family: $font-quicksand;
}
.font-awesome-bold {
  font-weight: 900;
}
.font-bold {
  font-weight: $font-weight-bold;
}
.font-semi-bold {
  font-weight: $font-weight-semibold;
}
.font-medium {
  font-weight: $font-weight-medium;
}
.font-normal {
  font-weight: $font-weight-normal;
}
.font-light {
  font-weight: $font-weight-light;
}
h1{
  font-size: $font-size-h1;
}
h2{
  font-size: $font-size-h2;
}
h3{
  font-size: $font-size-h3;
}
h4{
  font-size: $font-size-h4;
}
h5{
  font-size: $font-size-h5;
}
h6{
  font-size: $font-size-h6;
}
h7{
  font-size: $font-size-h7;
}
h8{
  font-size: $font-size-h8;
}
.container{
  margin: auto;
  position: relative;
  @media (min-width: 768px){
    max-width: 100%;
  }
}
//
//@media (min-width: 768px) {
//  .container {
//    width: 750px;
//    min-width: 750px;
//  }
//}
//@media  (min-width: 992px) {
//  .container {
//    width: 970px;
//    min-width: 970px;
//  }
//}
@media (min-width: 1600px) {
  .container {
    width: 1600px;
  }
}
//@media (min-width: 1600px) {
//  .container {
//    width: 1600px;
//    min-width: 1600px;
//  }
//}
h2.heading {
  cursor: default;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: $color-black;
  font-size: 4.0rem;
  line-height: 1.2;
  margin-bottom: 33px;
}
h2.heading+p {
  text-align: center;
  color: $color-black;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 300;
}

.btn{
  border-radius: 10px ;
  padding: 13px 25px;
  font-size: $font-size-h5;
  font-weight: $font-weight-semibold;
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none!important;
}
.btn:hover{
  //font-weight: 500!important;
}
.btn:focus {
  box-shadow: none!important;
}
.btn-outline-secondary:hover, .btn-outline-secondary:active {
  border-color: $color-orange-2!important;
  background: $color-orange-2!important;
  color: #fff;
}
.btn-outline-secondary:focus {
  border-color: $color-orange!important;
  background: $color-orange!important;
}

.btn-primary {
  background-color: $color-orange;
  border-color: $color-orange;
  color: #fff;
}
.btn-primary:hover,.btn-primary:active, .btn-primary:focus {
  background-color: $color-orange-2!important;
  border-color: $color-orange-2!important;
  color: #fff;
}
.btn-primary:active:focus, {
  background-color: $color-orange!important;
  border-color: $color-orange!important;
  color: #fff;
}
.form input {
  //outline: 1px solid $grey-color;
  border: 1px solid $color-grey-1;
  border-radius: 10px ;
  width: 100%;
  max-width: 500px;
  margin: 0 0 17px 0px;
  padding: 17px;
  box-sizing: border-box;
  font-size: $font-size-h5;
  outline: none!important;
  color: $color-grey-2;
  &::placeholder{
    color: $color-grey;
  }
}
.form button {
  margin-top: 25px;
}
.form button:hover,.form button:active,.form button:focus {
  background: $color-orange-2;
}
.form .form_info {
  text-align: left;
  margin-top: -15px;
  margin-bottom: 15px;
  a {
    text-decoration: none;
    color: $color-orange-2;
  }
  span{
    font-size: $font-size-h7;
  }
}
.my-terms_accepted{
  //display: table;
  input{
    width: 1.8rem;
  }
  position: relative;
  margin-top: 5px;
  font-size: $font-size-h6;
  a {
    color: $color-orange;
    text-decoration: none;
  }
  a:hover{
    color: $color-orange-2;
  }
}
input.form-check-input{
  margin: 0.5rem 0;
  font-size: 5rem;
  padding: 0;
  border-radius: 5px;
}
.hidden{
  display: none;
}

/******************** Custom checkbox ************************/
input[type="checkbox"], input[type="radio"]{
  position: absolute;
  outline: none;
  right: 9000px;
}
input[type="checkbox"] + .form-check-label{
  outline: none;
  border: none;
}
input[type="checkbox"] + .form-check-label:before{
  content: "\f0c8";
  font-family: "AwesomePro";
  font-size: 1.8rem;
  border-radius: 5px;
  color: $color-grey-1;
  speak: none;
  font-style: normal;
  font-weight: $font-weight-light;
  //font-variant: normal;
  text-transform: none;
  line-height: 1.8;
  -webkit-font-smoothing:antialiased;
  width: 1.8rem;
  display: inline-block;
  margin-right: 5px;
  outline: none;
  border: none;
  transform: scale(1.1);
}

input[type="checkbox"]:checked + .form-check-label:before{
  content: "\f14a";
  color: $color-orange;
  font-weight: bold;
  animation: effect 250ms ease-in;
  outline: none;
  border: none;
}

input[type="checkbox"]:disabled + .form-check-label{
  color: #fff;
}

input[type="checkbox"]:disabled + .form-check-label:before{
  content: "\f0c8";
  color: #fff;
}


/*Radio box*/
input[type="radio"] + .form-check-label{
  outline: none;
}
input[type="radio"] + .form-check-label:before{
  content: "\f111";
  font-family: "AwesomePro";
  font-size: 1.8rem;
  border-radius: 5px;
  color: $color-grey-1;
  speak: none;
  font-style: normal;
  font-weight: $font-weight-light;
  //font-variant: normal;
  text-transform: none;
  line-height: 1.8;
  -webkit-font-smoothing:antialiased;
  width: 1.8rem;
  display: inline-block;
  margin-right: 5px;
  outline: none;
  border: none;
  transform: scale(1.1);
}

input[type="radio"]:checked + .form-check-label:before{
  content: "\f192";
  color: $color-orange;
  font-weight: bold;
  animation: effect 250ms ease-in;
  outline: none;
  border: none;
}

input[type="radio"]:disabled + .form-check-label{
  color: #ccc;
}

input[type="radio"]:disabled + .form-check-label:before{
  content: "\f192";
  color: #ccc;
}

//
//input[type="radio"] + .form-check-label:before{
//  content: "\f111";
//  font-family: "Font Awesome 5 Free";
//  border-radius: 5px;
//  border-color: $color-grey-1;
//  speak: none;
//  font-style: normal;
//  font-weight: normal;
//  font-variant: normal;
//  text-transform: none;
//  line-height: 1.8;
//  -webkit-font-smoothing:antialiased;
//  width: 1.8rem;
//  display: inline-block;
//  margin-right: 5px;
//  outline: none;
//  transform: scale(1.1);
//}
//
//input[type="radio"]:checked + .form-check-label:before{
//  content: "\f192";
//  color: $color-orange;
//  font-weight: bold;
//  animation: effect 250ms ease-in;
//  outline: none;
//}
//
//input[type="radio"]:disabled + .form-check-label{
//  color: #aaa;
//}
//
//input[type="radio"]:disabled + .form-check-label:before{
//  content: "\f111";
//  color: #ccc;
//}
/********************animation ************************/
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes effect{
  0%{transform: scale(0);}
  25%{transform: scale(1.3);}
  75%{transform: scale(1.4);}
  100%{transform: scale(1.0);}
}
